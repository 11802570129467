import React from 'react'

export default function Page404() {
  return (
    <> 
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='Image404'>
              <img src='/assets/404.png' />

            </div>

          </div>

        </div>

      </div>
    </>
  )
}
